import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';


@Component({
  selector: 'app-agents-search',
  templateUrl: './agents-search.component.html',
  styleUrls: ['./agents-search.component.scss']
})
export class AgentsSearchComponent implements OnInit {
  formAgents:UntypedFormGroup;
  @Output() onSearchChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.formAgents = this.fb.group({
      name:null,
      email:null
    })
    
  }
  

  public searchFilters(){
    this.onSearchChange.emit(this.formAgents);
  }
  onKeyDown(event: KeyboardEvent) {
  if (event.key === "Enter") {
    // Aquí puedes llamar a la función para activar los filtros
    this.onSearchChange.emit(this.formAgents);

  }
}

  public reset(){     
    this.formAgents.reset({ 
      name:null,
      email:null     
    }); 
    this.onSearchChange.emit(this.formAgents);
  }
}
