// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   // url: 'http://192.168.5.15:8000',
//   url:'http://192.168.4.246:8000',
//   urlDom:'http://localhost:4200',
//   token:'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiYTFmM2Y1NjZmMGQwMTdiZjE2YzMxNmE3MjAzYzEyYzFlZDNkNjhkYTMyOTg0NjI3MDlmNjY2MWVkMmZjYzc2NzFjZjQ5MzYyOGE1N2E4YTYiLCJpYXQiOjE2ODYzMjgwNTMuODA3NzA3MDcxMzA0MzIxMjg5MDYyNSwibmJmIjoxNjg2MzI4MDUzLjgwNzcxMDg4NjAwMTU4NjkxNDA2MjUsImV4cCI6MTcxNzk1MDQ1My43ODY5MDA5OTcxNjE4NjUyMzQzNzUsInN1YiI6IjEiLCJzY29wZXMiOlsiKiJdfQ.ciFDmhXimEQU06t7iRkyzIo2Xw4EsyZtkMvkgi7pjwEGQp79g2pulu-O92lvijauHlFCw8wkhxRxLNPcDsH_dNZRF3CiAfhjd5wL1fTE4TiMG7ukw2gANnS5xY_tVX2H0OFKllrHFJb1q2A6hMhZvLa7tK5e6q5JDfI8vVg8Mmps0F2eVdyBjQlHmrNwKfjofVrrd5Jy8fZOVNCblpu0O_8UWbvCTWavZCfQgi8SunlSjXdfEUZp3SPkdsTMgIQb3XPqp_wUmDqKr0neHS3bceKm5A5RPbuTBSCpwUe8sbXGhYsIWDk_x8r7ZOnaO3wocoZav7I2RgCStkxwBooMyxRBga17MgOX6PbTihc_WNO04miGgzBUHVmxqM-LDYhUUX9xiwYaCZPcgOChFSeIC37njuusm6vgD652PZBV_l-5b7eGAoAIxk-4_wp340_jAvZ_b1dZVq3VrTUC6Pqyy148ExvNEtTCd5gxsoksTCRzVfzFpz-zPZzXT8kY78AQtngVDvRKRbtXgezcvRcHfO9uxRvHSMnNoxLq5P3q5UMZMJR2lItmJgZItP-zcqOg50NuV0UdQiGuDuomWbNxm-omKUbRNHQeNIhX_0TZY7kjVDF2ak8-yzFfzDDGYCcyKgwBfgM06ropDO_e9MXUdxxntDxrN4A2FMQQFb1KCDY'
// };

export const environment = {
  production: false,
  // url: 'http://192.168.5.15:8000',
  url:'https://crmred.co',
  // url:'http://192.168.4.246:8000',
  urlDom:'https://redfincaraiz.com',
  token:'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiYTcxZjI1MmE3ZmQ5MDI0MTk2YTEzNWEyNjBiZjg1ZWYxZWE4YzE0ZDUwOWFjYTY0NmE3Y2I4NTU3M2UzOTIyODNjM2ZjZjhlZWNjOWQzMzYiLCJpYXQiOjE2ODY3NTA2MDIuODU0ODAxLCJuYmYiOjE2ODY3NTA2MDIuODU0ODE5LCJleHAiOjE3MTgzNzMwMDIuNjQ4Nzk1LCJzdWIiOiIxIiwic2NvcGVzIjpbIioiXX0.q-GLPRSNgrVnKhung-5ijHQ7e16GC3nlE01_ktDF5ySp_78VY4bj1MAniJG9C9b3H9IfOx5wQ3kSqfnK96B_r89oV8xsQ_gt1gD_QnGa_2y2YEpmjD-7g_sCbSEsjKc4qipm2BYsHPmbGy2tutg_p8jmnwExOELqj-_jLdUpI5zzqUxDKfvVCckLOACyI-AyRcLtCv05lUi9ld9drhryLbXly1-Ec791jFbmUj7uZS_a8hDW0qCOANBWNxeNd_rif_B0pJM8qSemSFpp4Hx_XvIEtxNi5MGUhR7FZg0fPnbHbhw7ZhAp-Y-ljrNWRAvBZi1maDVgzIop55hYcrELl1IiRNXmIUVWbd3jUs5euCqzz_urVcMt7UG88xGq2ayxg39HjX-gOxTI7k03c7rTrasJmslNVQcROuw8ZjphiVioUmoUlw7t9tKbrhO4i3FwN_EoP0Akzia9mav6Y6PEAFGmwsGbmymmzD6t3BFw_Sz2vZO2dQvEYq2GSHBuC2gPnKyoT7E6aLjDDck7Vdx5UNrR5phKV1uxw7O8V3EVfk_UuwQPwjqTbwPaVKzQjbQs5bhD4GDkallIgMfNNjT1rcuXuYjyFrx7E-VfI3tuAWwR00z21ZPyIUGal1x0ch9TVU1j79mIOd1HuDMfgcj43ZAKFfkR0vU7X54XfzSwGoE'
  // token:'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiYTFmM2Y1NjZmMGQwMTdiZjE2YzMxNmE3MjAzYzEyYzFlZDNkNjhkYTMyOTg0NjI3MDlmNjY2MWVkMmZjYzc2NzFjZjQ5MzYyOGE1N2E4YTYiLCJpYXQiOjE2ODYzMjgwNTMuODA3NzA3MDcxMzA0MzIxMjg5MDYyNSwibmJmIjoxNjg2MzI4MDUzLjgwNzcxMDg4NjAwMTU4NjkxNDA2MjUsImV4cCI6MTcxNzk1MDQ1My43ODY5MDA5OTcxNjE4NjUyMzQzNzUsInN1YiI6IjEiLCJzY29wZXMiOlsiKiJdfQ.ciFDmhXimEQU06t7iRkyzIo2Xw4EsyZtkMvkgi7pjwEGQp79g2pulu-O92lvijauHlFCw8wkhxRxLNPcDsH_dNZRF3CiAfhjd5wL1fTE4TiMG7ukw2gANnS5xY_tVX2H0OFKllrHFJb1q2A6hMhZvLa7tK5e6q5JDfI8vVg8Mmps0F2eVdyBjQlHmrNwKfjofVrrd5Jy8fZOVNCblpu0O_8UWbvCTWavZCfQgi8SunlSjXdfEUZp3SPkdsTMgIQb3XPqp_wUmDqKr0neHS3bceKm5A5RPbuTBSCpwUe8sbXGhYsIWDk_x8r7ZOnaO3wocoZav7I2RgCStkxwBooMyxRBga17MgOX6PbTihc_WNO04miGgzBUHVmxqM-LDYhUUX9xiwYaCZPcgOChFSeIC37njuusm6vgD652PZBV_l-5b7eGAoAIxk-4_wp340_jAvZ_b1dZVq3VrTUC6Pqyy148ExvNEtTCd5gxsoksTCRzVfzFpz-zPZzXT8kY78AQtngVDvRKRbtXgezcvRcHfO9uxRvHSMnNoxLq5P3q5UMZMJR2lItmJgZItP-zcqOg50NuV0UdQiGuDuomWbNxm-omKUbRNHQeNIhX_0TZY7kjVDF2ak8-yzFfzDDGYCcyKgwBfgM06ropDO_e9MXUdxxntDxrN4A2FMQQFb1KCDY'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
